import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dropdown, Icon, Menu, Tooltip } from 'antd';
import { withI18n } from 'react-i18next';
import { Link, withRouter } from 'found';

import NotificationIcon from 'app/components/commons/NotificationIcon';
import config from 'config';
import styles from './styles.module.css';
import { MainHeaderProps } from './properties';
import { RootState } from 'app/redux/store';

class MainHeader extends React.PureComponent<MainHeaderProps> {
  public render() {
    const { loggedInUser, collapsed, onCollapse, t } = this.props;

    const menu = (
      <Menu selectedKeys={[]} className={styles.menu}>
        <Menu.Item disabled>
          <Icon type="user" /> Profile
        </Menu.Item>
        <Menu.Item disabled>
          <Icon type="setting" /> Settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          {!!config.OLD_ADMIN_URL ? (
            <a href={config.OLD_ADMIN_URL}>
              <Icon type="logout" /> Back to UCRM
            </a>
          ) : (
            <Link to="/logout">
              <Icon type="logout" /> {t('common:logOut')}
            </Link>
          )}
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={styles.root}>
        <div />
        {/* <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={onCollapse} /> */}

        <div>
          <Tooltip title={t('common:help')}>
            <span className={styles.action}>
              <Icon type="question-circle-o" />
            </span>
          </Tooltip>
          <span className={styles.action}>
            <NotificationIcon />
          </span>

          {loggedInUser && (
            <Dropdown overlay={menu}>
              <span className={styles.action}>{loggedInUser.username}</span>
            </Dropdown>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedInUser: state.auth,
});

export default compose(
  connect(mapStateToProps),
  withI18n(),
  withRouter,
)(MainHeader);
