/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import { ModulePermission, ModuleType } from '@ucentric/constants';
import { AuthorizedRoute as BaseAuthorizedRoute } from '@ucentric/react-components';
import { userRoleProfileModulePermissionValidator } from '@ucentric/validator';

import config from 'config';

class AuthorizedRoute extends BaseAuthorizedRoute {
  public isRouteAuthorized = ({ pathname }: Location, user: User) => {
    if (!config.ENABLE_ROLE_MATRIX) {
      return true;
    }

    // Administration
    if (pathname.match(/\/administration$/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Administration,
        permissions: [ModulePermission.Read],
      });
    }
    /// AuthorizationManagement
    //// Department
    if (pathname.match(/\/administration\/departments\/?.*/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Department,
        permissions: [ModulePermission.Read],
      });
    }
    //// Profile
    if (pathname.match(/\/administration\/profiles\/?.*/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Profile,
        permissions: [ModulePermission.Read],
      });
    }
    //// Role
    if (pathname.match(/\/administration\/roles\/?.*/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Role,
        permissions: [ModulePermission.Read],
      });
    }
    /// ConfigurationManagement
    // @todo role-matrix-missing
    /// AdminUserManagement
    //// AdminUser
    if (pathname.match(/\/administration\/users\/?.*/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.AdminUser,
        permissions: [ModulePermission.Read],
      });
    }

    // Sales
    if (pathname.match(/\/sales$/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Sales,
        permissions: [ModulePermission.Read],
      });
    }
    /// ApplicationRequestManagement
    //// ApplicationRequest
    if (pathname.match(/\/sales\/applications\/?.*/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.ApplicationRequest,
        permissions: [ModulePermission.Read],
      });
    }
    /// PartnershipManagement
    if (pathname.match(/\/sales\/partners\/?.*/)) {
      //// CommissionRate
      if (pathname.match(/\/sales\/partners\/commissions\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CommissionRate,
          permissions: [ModulePermission.Read],
        });
      }
      //// Partner
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Partner,
        permissions: [ModulePermission.Read],
      });
    }

    // QuotationManagement
    if (pathname.match(/\/sales\/quotations\/?.*/)) {
      //// QuotationConfiguration
      if (pathname.match(/\/sales\/quotations\/configuration\/?.*/)) {
        return (
          config.ENABLE_QUOTATION &&
          userRoleProfileModulePermissionValidator(user, {
            moduleType: ModuleType.QuotationConfiguration,
            permissions: [ModulePermission.Read],
          })
        );
      }
      //// Quotation
      return (
        config.ENABLE_QUOTATION &&
        userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.Quotation,
          permissions: [ModulePermission.Read],
        })
      );
    }

    // Services
    if (pathname.match(/\/services$/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Services,
        permissions: [ModulePermission.Read],
      });
    }
    /// TicketManagement
    if (pathname.match(/\/services\/cases\/?.*/)) {
      //// TicketConfiguration
      if (pathname.match(/\/services\/cases\/configuration\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.TicketConfiguration,
          permissions: [ModulePermission.Read],
        });
      }
      //// Ticket
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Ticket,
        permissions: [ModulePermission.Read],
      });
    }
    /// CustomerAccountManagement
    if (pathname.match(/\/services\/customers\/?.*/)) {
      //// CustomerAccountFactsheet
      if (pathname.match(/\/services\/customers\/?.*\/overview$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CustomerAccountFactsheet,
          permissions: [ModulePermission.Read],
        });
      }
      //// CustomerAccount
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.CustomerAccount,
        permissions: [ModulePermission.Read],
      });
    }
    /// SMSFeedhbackManagement
    if (pathname.match(/\/services\/sms-feedbacks\/?.*/)) {
      //// SmsFeedbackReport
      if (pathname.match(/\/services\/sms-feedbacks\/reports$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.SmsFeedbackReport,
          permissions: [ModulePermission.Read],
        });
      }
      //// ActivitySmsHistory
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.ActivitySmsHistory,
        permissions: [ModulePermission.Read],
      });
    }

    // Marketing
    if (pathname.match(/\/marketing$/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Marketing,
        permissions: [ModulePermission.Read],
      });
    }
    /// LeadManagement
    // @todo role-matrix-missing reports and configuration
    if (pathname.match(/\/marketing\/leads\/?.*/)) {
      //// LeadListManagement
      if (pathname.match(/\/marketing\/leads\/import$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.LeadListManagement,
          permissions: [ModulePermission.Read],
        });
      }
      //// Lead
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Lead,
        permissions: [ModulePermission.Read],
      });
    }
    /// TariffRateManagement
    // @todo role-matrix-missing templates, reports and configuration
    if (pathname.match(/\/marketing\/tariff-rates\/?.*/)) {
      /// Tariff-rate configuration
      if (pathname.match(/\/marketing\/tariff-rates\/configuration\/?.*/)) {
        //// TariffRate
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.TariffRateConfiguration,
          permissions: [ModulePermission.Read],
        });
      }
      //// TariffRate
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.TariffRate,
        permissions: [ModulePermission.Read],
      });
    }
    /// CampaignManagement
    // @todo role-matrix-missing sms builder, unsubscribes, reports, and configuration
    if (pathname.match(/\/marketing\/campaigns\/?.*/)) {
      //// SourceList
      if (pathname.match(/\/marketing\/campaigns\/sources$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.SourceList,
          permissions: [ModulePermission.Read],
        });
      }
      //// CampaignTemplate
      if (pathname.match(/\/marketing\/campaigns\/templates$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CampaignTemplate,
          permissions: [ModulePermission.Read],
        });
      }
      //// CampaignConfiguration
      if (pathname.match(/\/marketing\/campaigns\/configuration\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CampaignConfiguration,
          permissions: [ModulePermission.Read],
        });
      }
      //// Campaign
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Campaign,
        permissions: [ModulePermission.Read],
      });
    }
    /// AffiliateProgramManagement
    // @todo role-matrix-missing configuration
    if (pathname.match(/\/marketing\/affiliate-programs\/?.*/)) {
      //// AffiliateProgramConfiguration
      if (pathname.match(/\/marketing\/affiliate-programs\/configuration\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.AffiliateProgramConfiguration,
          permissions: [ModulePermission.Read],
        });
      }
      //// AffiliateProgram
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.AffiliateProgram,
        permissions: [ModulePermission.Read],
      });
    }
    /// LoyaltyManagement
    if (pathname.match(/\/marketing\/loyalty\/?.*/)) {
      //// Offer
      if (pathname.match(/\/marketing\/loyalty\/products\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.Offer,
          permissions: [ModulePermission.Read],
        });
      }
      //// OfferCatalog
      if (pathname.match(/\/marketing\/loyalty\/product-catalogs\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.OfferCatalog,
          permissions: [ModulePermission.Read],
        });
      }
      //// Order
      if (pathname.match(/\/marketing\/loyalty\/orders\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.Order,
          permissions: [ModulePermission.Read],
        });
      }
      //// UpdateCreditsAction
      if (pathname.match(/\/marketing\/loyalty\/point-histories\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.UpdateCreditsAction,
          permissions: [ModulePermission.Read],
        });
      }
      //// LoyaltyConfiguration
      if (pathname.match(/\/marketing\/loyalty\/configuration\/?.*/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.LoyaltyConfiguration,
          permissions: [ModulePermission.Read],
        });
      }
    }

    // CustomerPortal
    if (pathname.match(/\/customer-portal$/)) {
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.CustomerPortal,
        permissions: [ModulePermission.Read],
      });
    }
    /// UserManagement
    if (pathname.match(/\/customer-portal\/users\/?.*/)) {
      //// User
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.User,
        permissions: [ModulePermission.Read],
      });
    }

    if (pathname.match(/\/customer-portal\/configuration\/faq\/?.*/)) {
      //// User
      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.FAQ,
        permissions: [ModulePermission.Read],
      });
    }

    // Report
    if (pathname.match(/\/reports\/?.*/)) {
      // SaleReport
      /// ApplicationRequestReport
      if (pathname.match(/\/reports\/applications$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.ApplicationRequestReport,
          permissions: [ModulePermission.Read],
        });
      }

      // ServiceReport
      /// CustomerAccountReport
      if (pathname.match(/\/reports\/customers$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CustomerAccountReport,
          permissions: [ModulePermission.Read],
        });
      }
      /// ContractReport
      if (pathname.match(/\/reports\/contracts$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.ContractReport,
          permissions: [ModulePermission.Read],
        });
      }
      /// CustomerAccountRelationshipReport
      if (pathname.match(/\/reports\/customer-relationships$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.CustomerAccountRelationshipReport,
          permissions: [ModulePermission.Read],
        });
      }
      /// TicketReport
      if (pathname.match(/\/reports\/cases$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.TicketReport,
          permissions: [ModulePermission.Read],
        });
      }

      // MarketingReport
      /// LeadReport
      if (pathname.match(/\/reports\/leads$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.LeadReport,
          permissions: [ModulePermission.Read],
        });
      }
      if (pathname.match(/\/reports\/redemption-orders$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.OrderReport,
          permissions: [ModulePermission.Read],
        });
      }
      if (pathname.match(/\/reports\/point-transactions$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.PointCreditsActionReport,
          permissions: [ModulePermission.Read],
        });
      }

      // CustomerPortalReport
      /// UserReport
      if (pathname.match(/\/reports\/users$/)) {
        return userRoleProfileModulePermissionValidator(user, {
          moduleType: ModuleType.UserReport,
          permissions: [ModulePermission.Read],
        });
      }

      return userRoleProfileModulePermissionValidator(user, {
        moduleType: ModuleType.Report,
        permissions: [ModulePermission.Read],
      });
    }

    return true;
  };
}

export default AuthorizedRoute;
