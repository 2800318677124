import * as React from 'react';
import moment from 'moment';
import { ApiRoute } from '@ucentric/constants';
import { Card } from 'antd';
import { Formik, FormikActions } from 'formik';
import { PageList, PointTransactionOptionSearchFieldset, PointTransactionReportTable } from '@ucentric/react-components';
import { apiFetch, apiUpdate, getPaginationByIri, getResourcesByIri, getRequestStatusByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get, filter, isEmpty, isObject, zipObject } from 'lodash';
import { translate } from 'react-i18next';

import styles from './styles.module.css';
import { POINT_TRANSACTION_HISTORY_PATHS } from 'app/constants';
import { PointTransactionReportPageOuterProps, PointTransactionReportPageProps } from './properties';
import { RootState } from 'app/redux/store';

function makePointTransactionSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;

  return {
    itemsPerPage: 10,
    ...apiSearchQuery.pointTransactionReportSearchQuery(query),
    ...(!hasSorter ? { 'order[dateCreated]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',');
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }
      return value;
    }),
  );
}

const PointTransactionReportPage = ({
  action,
  isFetching,
  earnContractCredits,
  redeemCredits,
  location,
  pagination,
  router,
  status,
  t,
}: PointTransactionReportPageProps) => {
  const [pointTransactionSearchQuery, setPointTransactionSearchQuery] = React.useState(location.query);

  const searchQuery = makePointTransactionSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const handleReset = () => {
    router.push({
      pathname: location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const initialValues = {};

  const handleSubmit = async (values: UpdateCreditsAction, { setSubmitting }: FormikActions<UpdateCreditsAction>) => {
    router.push({
      ...location,
      query: {
        ...pointTransactionSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if (isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    if (query['filters:transactionType'] === 'RED') {
      fetchData(ApiRoute.RedeemCreditsAction);
    } else if (query['filters:transactionType'] === 'ACC') {
      fetchData(ApiRoute.EarnContractCreditsAction);
    } else {
      Promise.all([fetchData(ApiRoute.RedeemCreditsAction), fetchData(ApiRoute.EarnContractCreditsAction)]);
    }
  };

  const fetchData = (apiRouteType: ApiRoute) => {
    action.apiFetch(apiRouteType, makePointTransactionSearchQuery(location.query), {
      subrequests: POINT_TRANSACTION_HISTORY_PATHS,
    });
  };

  const dataSource = (query: {}) => {
    if (query['filters:transactionType'] === 'RED') {
      return redeemCredits;
    } else if (query['filters:transactionType'] === 'ACC') {
      return earnContractCredits;
    } else {
      return [...earnContractCredits, ...redeemCredits];
    }
  };

  return (
    <PageList title={`${t('common:pointTransactionHistory')} ${t('common:report')}`}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
                ? normalizeValues(initialValues)
                : normalizeValues(location.query)
            }
            onReset={handleReset}
            onSubmit={handleSubmit}
            render={(formikProps) => (
              <div>
                <PointTransactionOptionSearchFieldset
                  value={pointTransactionSearchQuery}
                  onChange={(values) => {
                    setPointTransactionSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <PointTransactionReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : dataSource(location.query)}
          loading={isFetching}
          params={makePointTransactionSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage * 2,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: PointTransactionReportPageProps) => {
  let pagination;
  let status;

  const searchQuery = makePointTransactionSearchQuery(query);
  const contractActionStatus = getRequestStatusByIri(state, ApiRoute.EarnContractCreditsAction, searchQuery);
  const redeemActionStatus = getRequestStatusByIri(state, ApiRoute.RedeemCreditsAction, searchQuery);
  const contractActionPagination = getPaginationByIri(
    state,
    ApiRoute.EarnContractCreditsAction,
    makePointTransactionSearchQuery(query),
  );

  const redeemActionPagination = getPaginationByIri(state, ApiRoute.RedeemCreditsAction, makePointTransactionSearchQuery(query));

  if (!!contractActionPagination && !!redeemActionPagination) {
    pagination =
      contractActionPagination.lastPage > redeemActionPagination.lastPage ? contractActionPagination : redeemActionPagination;
  } else if (!!contractActionPagination) {
    pagination = contractActionPagination;
  } else if (!!redeemActionPagination) {
    pagination = redeemActionPagination;
  }

  if (!!contractActionStatus && !!redeemActionStatus) {
    status = contractActionStatus.pending > redeemActionStatus.pending ? contractActionStatus : redeemActionStatus;
  } else if (!!contractActionStatus) {
    status = contractActionStatus;
  } else if (!!redeemActionStatus) {
    status = redeemActionStatus;
  }

  return {
    isFetching: !!status && status.pending > 0,
    pagination,
    status: state.statuses,
    earnContractCredits: getResourcesByIri<EarnContractCreditsAction>(
      state,
      ApiRoute.EarnContractCreditsAction,
      makePointTransactionSearchQuery(query),
      undefined,
      {
        subresources: POINT_TRANSACTION_HISTORY_PATHS,
      },
    ),
    redeemCredits: getResourcesByIri<RedeemCreditsAction>(
      state,
      ApiRoute.RedeemCreditsAction,
      makePointTransactionSearchQuery(query),
      undefined,
      {
        subresources: POINT_TRANSACTION_HISTORY_PATHS,
      },
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      apiUpdate,
    },
    dispatch,
  ),
});

export default compose<PointTransactionReportPageProps, PointTransactionReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate(),
)(PointTransactionReportPage);
