/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import { AccountCategory, AccountType, ApiRoute } from '@ucentric/constants';
import { BAD_REQUEST } from 'http-status-codes';
import { GetDataArgs, HttpError } from 'found';
import { apiFetch, requestBridgeAuthTokenRequest } from '@ucentric/redux';
import { apiUtils, apiFilter } from '@ucentric/utils';

import {
  ADVISORY_NOTICE_SUBRESOURCE_PATHS,
  AFFILIATE_PROGRAM_SUBRESOURCE_PATHS,
  APPLICATION_REQUEST_SUBRESOURCE_PATHS,
  CAMPAIGN_SUBRESOURCE_PATHS,
  CAMPAIGN_TEMPLATE_SUBRESOURCE_PATHS,
  CONTRACT_SUBRESOURCE_PATHS,
  CUSTOMER_ACCOUNT_FACTSHEET_SUBRESOURCE_PATHS,
  CUSTOMER_ACCOUNT_PARTNER_SUBRESOURCE_PATHS,
  CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS,
  DEPARTMENT_SUBRESOURCE_PATHS,
  LEAD_SUBRESOURCE_PATHS,
  MAINTENANCE_CONFIGURATION_SUBRESOURCE_PATHS,
  OFFER_CATALOG_SUBRESOURCE_PATHS,
  ORDER_SUB_RESOURCE_PATHS,
  PROFILE_SUBRESOURCE_PATHS,
  PROMOTION_SUBRESOURCE_PATHS,
  QUOTATION_PRICE_CONFIGURATION_SUBRESOURCE_PATHS,
  QUOTATION_SUBRESOURCE_PATHS,
  ROLE_SUBRESOURCE_PATHS,
  TARIFF_RATE_SUBRESOURCE_PATHS,
  TICKET_CATEGORY_SUBRESOURCE_PATHS,
  TICKET_SUBRESOURCE_PATHS,
  TICKET_TYPE_SUBRESOURCE_PATHS,
  TICKETS_SUBRESOURCE_PATHS,
  USER_SUBRESOURCE_PATHS,
  MESSAGE_TEMPLATE_SUBRESOURCE_PATHS,
  APPLICATION_TEMPLATE_SUBRESOURCE_PATHS,
} from 'app/constants';

export function fetchAdvisoryNotice(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.AdvisoryNotice}/${id}`, undefined, {
        subrequests: ADVISORY_NOTICE_SUBRESOURCE_PATHS,
      }),
    );
}

export async function fetchCustomerAccount({
  context: {
    store: { dispatch },
  },
  params: { accountNumber },
}: GetDataArgs) {
  const customerAccountHydra: Hydra<CustomerAccount> = await dispatch(
    apiFetch(
      ApiRoute.CustomerAccount,
      { accountNumber, itemsPerPage: 1 },
      {
        subrequests: CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS,
      },
    ),
  );
  const [customerAccount] = customerAccountHydra['hydra:member'];

  return customerAccount;
}

export async function fetchCustomerAccountPartner({
  context: {
    store: { dispatch },
  },
  params: { accountNumber },
}: GetDataArgs) {
  const customerAccountHydra: Hydra<CustomerAccount> = await dispatch(
    apiFetch(
      ApiRoute.CustomerAccount,
      { accountNumber, itemsPerPage: 1 },
      {
        subrequests: [...CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS, ...CUSTOMER_ACCOUNT_PARTNER_SUBRESOURCE_PATHS],
      },
    ),
  );
  const [customerAccount] = customerAccountHydra['hydra:member'];

  // if partner fetch employees!
  // if (
  //   !!customerAccount &&
  //   customerAccount.type === AccountType.Corporate &&
  //   customerAccount.categories.some(
  //     (category) => category === AccountCategory.Partner || category === AccountCategory.PartnerContactPerson,
  //   )
  // ) {
  //   await dispatch(
  //     apiFetch(
  //       `${customerAccount.corporationDetails['@id']}/employees`,
  //       { itemsPerPage: 400 },
  //       {
  //         subrequests: ['employee', 'employee.user'],
  //       },
  //     ),
  //   );
  // }

  return customerAccount;
}

export async function fetchCustomerAccountContract({
  context: {
    store: { dispatch },
  },
  params: { contractNumber },
}: GetDataArgs) {
  let contract = dispatch(
    apiFetch(
      ApiRoute.Contract,
      { contractNumber, itemsPerPage: 1 },
      {
        subrequests: ['actions', 'actions.instrument', 'actions.object', 'actions.result'],
      },
    ),
  );
  if (contract) {
    contract = apiUtils.getActiveContract(contract);
    contract = dispatch(
      apiFetch(
        ApiRoute.Contract,
        { contractNumber, itemsPerPage: 1 },
        {
          subrequests: CONTRACT_SUBRESOURCE_PATHS,
        },
      ),
    );
  }

  return contract;
}

export function fetchCustomerAccountFactsheet({
  context: {
    store: { dispatch },
  },
  params: { accountNumber },
}: GetDataArgs) {
  return dispatch(
    apiFetch(
      ApiRoute.CustomerAccount,
      { accountNumber },
      {
        subrequests: CUSTOMER_ACCOUNT_FACTSHEET_SUBRESOURCE_PATHS,
      },
    ),
  );
}

export function fetchAffiliateProgram(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.AffiliateProgram}/${id}`, undefined, {
        ...meta,
        subrequests: AFFILIATE_PROGRAM_SUBRESOURCE_PATHS,
      }),
    );
}

export function fetchApplicationRequest(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { applicationRequestNumber },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(
        ApiRoute.ApplicationRequest,
        { applicationRequestNumber, itemsPerPage: 1 },
        {
          ...meta,
          subrequests: APPLICATION_REQUEST_SUBRESOURCE_PATHS,
        },
      ),
    );
}

export function fetchCampaign(meta?: {}) {
  return async ({
    context: {
      store: { dispatch },
    },
    params: { campaignNumber },
  }: GetDataArgs) => {
    const campaignHydra: Hydra<Campaign> = await dispatch(
      apiFetch(ApiRoute.Campaign, { campaignNumber, itemsPerPage: 1 }, { subrequests: CAMPAIGN_SUBRESOURCE_PATHS }),
    );
    let [campaign] = campaignHydra['hydra:member'];

    if (campaign) {
      campaign = {
        ...campaign,
        schedules: await dispatch(
          apiFetch(ApiRoute.EmailCampaignSchedule, {
            campaign: campaign['@id'],
          }),
        ),
      };
    }

    return campaign;
  };
}

export function fetchCampaignExpectation(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.CampaignExpectation}/${id}`));
}

export function fetchCampaignObjective(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.CampaignObjective}/${id}`));
}

export function fetchCreditsCategory(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.CreditsCategory}/${id}`));
}

export function fetchCreditsConfiguration(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.CreditsConfiguration}/${id}`));
}

export function fetchCreditsScheme(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => {
    return dispatch(apiFetch(`${ApiRoute.CreditsScheme}/${id}`));
  };
}

export function fetchCampaignTemplate(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.CampaignTemplate}/${id}`, undefined, {
        subrequests: CAMPAIGN_TEMPLATE_SUBRESOURCE_PATHS,
      }),
    );
}

export function fetchDataForApplicationRequestDetailsPage(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    location: {
      query: { lead, quotationNumber, customerAccountNumber },
    },
  }: GetDataArgs) => {
    const pageData = [dispatch(apiFetch(ApiRoute.AdvisoryNotice, apiFilter.ADVISORY_NOTICE_ACTIVE_FILTER))];
    if (!!lead) {
      pageData.push(dispatch(apiFetch(ApiRoute.Lead, { leadNumber: lead }, { ...meta, subrequests: LEAD_SUBRESOURCE_PATHS })));
    }

    if (!!quotationNumber) {
      pageData.push(
        dispatch(apiFetch(ApiRoute.Quotation, { quotationNumber }, { ...meta, subrequests: QUOTATION_SUBRESOURCE_PATHS })),
      );
    }

    if (!!customerAccountNumber) {
      pageData.push(
        dispatch(
          apiFetch(
            ApiRoute.CustomerAccount,
            { accountNumber: customerAccountNumber },
            {
              ...meta,
              subrequests: CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS,
            },
          ),
        ),
      );
    }

    return Promise.all(pageData);
  };
}

export function fetchFaqConfiguration(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.FaqConfiguration}/${id}`));
}

export function fetchFaqConfigurationCategory(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(ApiRoute.FaqConfigurationCategory, { id }));
}

export function fetchLead(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { leadNumber },
  }: GetDataArgs) => dispatch(apiFetch(ApiRoute.Lead, { leadNumber }, { ...meta, subrequests: LEAD_SUBRESOURCE_PATHS }));
}

export function fetchMerchant(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { merchantNumber },
  }: GetDataArgs) => dispatch(apiFetch(ApiRoute.Merchant, { merchantNumber }));
}

export function fetchOffer(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.Offer}/${id}`));
}

export function fetchOfferCatalog(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(apiFetch(`${ApiRoute.OfferCatalog}/${id}`, undefined, { subrequests: OFFER_CATALOG_SUBRESOURCE_PATHS }));
}

export function fetchOfferCategory(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { categoryNumber },
  }: GetDataArgs) => dispatch(apiFetch(ApiRoute.OfferCategory, { categoryNumber }));
}

export function fetchQuotation(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { quotationNumber },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(
        ApiRoute.Quotation,
        { quotationNumber, itemsPerPage: 1 },
        {
          ...meta,
          subrequests: QUOTATION_SUBRESOURCE_PATHS,
        },
      ),
    );
}

export function fetchQuotationPriceConfiguration(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.QuotationPriceConfiguration}/${id}`, undefined, {
        subrequests: QUOTATION_PRICE_CONFIGURATION_SUBRESOURCE_PATHS,
      }),
    );
}

export function fetchOrder(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(ApiRoute.Order + '/' + id, undefined, { ...meta, subrequests: ORDER_SUB_RESOURCE_PATHS }));
}

export function fetchPromotion(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.Promotion}/${id}`, undefined, {
        subrequests: PROMOTION_SUBRESOURCE_PATHS,
      }),
    );
}

export function fetchServiceLevelAgreement({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(apiFetch(`${ApiRoute.ServiceLevelAgreement}/${id}`));
}

export function fetchSourceList(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(`${ApiRoute.SourceList}/${id}`, undefined, {
        subrequests: ['itemListElement'],
      }),
    );
}

export function fetchTariffRate(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { tariffRateNumber },
  }: GetDataArgs) => {
    const pageData = [
      dispatch(
        apiFetch(
          ApiRoute.TariffRate,
          { tariffRateNumber, itemsPerPage: 1 },
          {
            ...meta,
            subrequests: TARIFF_RATE_SUBRESOURCE_PATHS,
          },
        ),
      ),
      dispatch(
        apiFetch(
          ApiRoute.TariffRateDisplayConfigurations,
        ),
      )
    ]

    return Promise.all(pageData);
  }
}

export function fetchTicket(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { ticketNumber },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(
        ApiRoute.Ticket,
        { ticketNumber },
        {
          ...meta,
          subrequests: TICKET_SUBRESOURCE_PATHS,
        },
      ),
    );
}

export function fetchCustomerFromQuery(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    location: { query },
  }: GetDataArgs) => {
    if (query.customerAccountNumber) {
      return dispatch(
        apiFetch(
          ApiRoute.CustomerAccount,
          { accountNumber: query.customerAccountNumber },
          {
            ...meta,
            subrequests: CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS,
          },
        ),
      );
    }
  };
}

export function fetchTickets(filter?: {}, meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(
        ApiRoute.Ticket,
        {
          ...filter,
          'order[dateCreated]': 'desc',
        },
        {
          ...meta,
          subrequests: TICKETS_SUBRESOURCE_PATHS,
        },
      ),
    );
}

export function fetchTicketCategory({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(apiFetch(`${ApiRoute.TicketCategory}/${id}`, undefined, { subrequests: TICKET_CATEGORY_SUBRESOURCE_PATHS }));
}

export function fetchTicketType({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(apiFetch(`${ApiRoute.TicketType}/${id}`, undefined, { subrequests: TICKET_TYPE_SUBRESOURCE_PATHS }));
}

export function fetchUser({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(
    apiFetch(`${ApiRoute.User}/${id}`, undefined, {
      subrequests: USER_SUBRESOURCE_PATHS,
    }),
  );
}

export function fetchProfile({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(
    apiFetch(`${ApiRoute.Profile}/${id}`, undefined, {
      subrequests: PROFILE_SUBRESOURCE_PATHS,
    }),
  );
}

export function fetchDepartment({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(
    apiFetch(`${ApiRoute.Department}/${id}`, undefined, {
      subrequests: DEPARTMENT_SUBRESOURCE_PATHS,
    }),
  );
}

export function fetchRole({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(
    apiFetch(`${ApiRoute.Role}/${id}`, undefined, {
      subrequests: ROLE_SUBRESOURCE_PATHS,
    }),
  );
}

export function requestBridgeAuthToken() {
  return ({
    context: {
      store: { dispatch },
    },
    location: {
      query: { token },
    },
  }: GetDataArgs) => {
    if (token) {
      dispatch(requestBridgeAuthTokenRequest(token));
    } else {
      throw new HttpError(BAD_REQUEST);
    }
  };
}

export function fetchMaintenanceConfiguration({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(
    apiFetch(`${ApiRoute.MaintenanceConfiguration}/${id}`, undefined, {
      subrequests: MAINTENANCE_CONFIGURATION_SUBRESOURCE_PATHS,
    }),
  );
}

export function fetchValidationRule({
  context: {
    store: { dispatch },
  },
  params: { id },
}: GetDataArgs) {
  return dispatch(apiFetch(`${ApiRoute.ValidationRule}/${id}`));
}

export function fetchMessageTemplate({
  context: {
    store: { dispatch },
  },
  params: { messageNumber },
}: GetDataArgs) {
  return dispatch(
    apiFetch(
      ApiRoute.MessageTemplate,
      { messageNumber },
      {
        subrequests: MESSAGE_TEMPLATE_SUBRESOURCE_PATHS,
      },
    ),
  );
}

export function fetchPartnershipReports({
  context: {
    store: { dispatch },
  },
  params: { type },
}: GetDataArgs) {
  return dispatch(apiFetch(ApiRoute.InternalDocument, { type }));
}

export function fetchApplicationTemplate(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) =>
    dispatch(apiFetch(`${ApiRoute.EmailTemplates}/${id}`, undefined,
    { subrequests: APPLICATION_TEMPLATE_SUBRESOURCE_PATHS }));
  }
