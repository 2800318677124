import { createRequestInstance } from 'redux-saga-requests';
import { fetch } from '@ucentric/utils';
import { fork } from 'redux-saga/effects';

import {
  interceptApiError,
  interceptApiRequest,
  interceptApiResponse,
  watchApiRequest,
  watchBridgeLogin,
  watchLogin,
  watchLogout,
  watchSignup,
  watchValidation,
} from '@ucentric/redux';

import { fetchDriver, watchRequests } from 'app/redux/utils';

function* rootSaga() {
  yield createRequestInstance(fetch, {
    driver: fetchDriver,
    onError: interceptApiError,
    onRequest: interceptApiRequest,
    onSuccess: interceptApiResponse,
  });
  yield fork(watchRequests, { takeLatest: false });
  yield fork(watchApiRequest);
  yield fork(watchLogin);
  yield fork(watchLogout);
  yield fork(watchSignup);
  yield fork(watchValidation);
  yield fork(watchBridgeLogin);
}

export default rootSaga;
