import * as React from 'react';
import moment from 'moment';
import {
  AccountType,
  ApiRoute,
  ApplicationRequestStatus,
  ApplicationRequestType,
  ContractType,
  ApplicationRequestSource,
} from '@ucentric/constants';
import { Card } from 'antd';
import { Formik, FormikActions } from 'formik';
import { ApplicationRequestOptionSearchFieldset, ApplicationRequestReportTable, PageList } from '@ucentric/react-components';
import { apiFetch, apiUpdate, getPaginationByIri, getResourcesByIri, getRequestStatusByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get, filter, isEmpty, isObject, without, zipObject } from 'lodash';
import { translate } from 'react-i18next';

import styles from './styles.module.css';
import { APPLICATION_REQUEST_REPORTS_SUBRESOURCE_PATHS } from 'app/constants';
import { ApplicationRequestReportPageOuterProps, ApplicationRequestReportPageProps } from './properties';
import { RootState } from 'app/redux/store';
import config from 'config';

function makeApplicationRequestSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;
  const statuses = (!!query['filters:status'] && query['filters:status'].split(',')) || [];

  if (statuses.includes(ApplicationRequestStatus.Draft) || statuses.includes(ApplicationRequestStatus.CustomerDraft)) {
    const sources = (!!query['filters:source'] && query['filters:source'].split(',')) || [];
    if (
      sources.includes(ApplicationRequestSource.PartnershipPortal) &&
      !statuses.includes(ApplicationRequestStatus.PartnerDraft)
    ) {
      statuses.push(ApplicationRequestStatus.PartnerDraft);
    }

    query['filters:status'] = statuses.join(',');
  }

  return {
    itemsPerPage: 10,
    status: without(Object.values(ApplicationRequestStatus), ApplicationRequestStatus.PartnerDraft),
    ...apiSearchQuery.applicationRequestSearchQuery(query),
    ...(!hasSorter ? { 'order[dateCreated]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',').filter((val) => val !== ApplicationRequestStatus.PartnerDraft);
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }

      return value;
    }),
  );
}

const ApplicationRequestReportPage = ({
  action,
  location,
  applicationRequests,
  isFetching,
  pagination,
  status,
  t,
  router,
}: ApplicationRequestReportPageProps) => {
  const [applicationRequestReportSearchQuery, setApplicationRequestReportSearchQuery] = React.useState(location.query);

  const searchQuery = makeApplicationRequestSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const initialValues = {};

  const handleReset = () => {
    router.push({
      pathname: location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const handleSubmit = async (values: ApplicationRequest, { setSubmitting }: FormikActions<ApplicationRequest>) => {
    router.push({
      ...location,
      query: {
        ...applicationRequestReportSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if (isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    action.apiFetch(ApiRoute.ApplicationRequest, makeApplicationRequestSearchQuery(query), {
      subrequests: APPLICATION_REQUEST_REPORTS_SUBRESOURCE_PATHS,
    });
  };

  let ineligibleColumns = [];
  switch (config.PROFILE) {
    case 'iswitch':
      ineligibleColumns = ['promotion.promotionNumber'];
      break;
    case 'unionpower':
      ineligibleColumns = [];
      break;
  }

  return (
    <PageList title={t('common:applicationRequest') + ' ' + t('common:report')}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
                ? normalizeValues(initialValues)
                : normalizeValues(location.query)
            }
            onReset={handleReset}
            onSubmit={handleSubmit}
            render={() => (
              <div>
                <ApplicationRequestOptionSearchFieldset
                  value={applicationRequestReportSearchQuery}
                  onChange={(values) => {
                    setApplicationRequestReportSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <ApplicationRequestReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : applicationRequests}
          ineligibleColumns={ineligibleColumns}
          loading={isFetching}
          params={makeApplicationRequestSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: ApplicationRequestReportPageProps) => {
  const searchQuery = makeApplicationRequestSearchQuery(query);
  const status = getRequestStatusByIri(state, ApiRoute.ApplicationRequest, searchQuery);

  return {
    applicationRequests: getResourcesByIri<ApplicationRequest>(state, ApiRoute.ApplicationRequest, searchQuery, undefined, {
      subresources: APPLICATION_REQUEST_REPORTS_SUBRESOURCE_PATHS,
    }),
    isFetching: !!status && status.pending > 0,
    pagination: getPaginationByIri(state, ApiRoute.ApplicationRequest, searchQuery),
    status: state.statuses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      apiUpdate,
    },
    dispatch,
  ),
});

export default compose<ApplicationRequestReportPageProps, ApplicationRequestReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate(),
)(ApplicationRequestReportPage);
