import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import resources from '@ucentric/locales';
// import { initReactI18n } from 'react-i18next/hooks';

i18next
  .use(LanguageDetector)
  // .use(initReactI18n)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18next;
