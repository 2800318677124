import * as React from 'react';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Formik, FormikActions } from 'formik';
import { AccountCategory, ApiRoute } from '@ucentric/constants';
import { PageList, UserOptionSearchFieldset, UserReportTable } from '@ucentric/react-components';
import { apiFetch, apiUpdate, getPaginationByIri, getResourcesByIri, getRequestStatusByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { get, filter, isEmpty, isObject, zipObject } from 'lodash';
import { translate } from 'react-i18next';

import styles from './styles.module.css';
import { USER_REPORTS_SUBRESOURCE_PATHS } from 'app/constants';
import { UserReportPageOuterProps, UserReportPageProps } from './properties';
import { RootState } from 'app/redux/store';

function makeUserSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;

  return {
    'customerAccount.categories': [AccountCategory.ContactPerson, AccountCategory.Customer],
    itemsPerPage: 10,
    ...apiSearchQuery.userSearchQuery(query),
    ...(!hasSorter ? { 'order[dateCreated]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',');
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }

      return value;
    }),
  );
}

const UserReportPage = ({ action, isFetching, users, location, pagination, router, status, t }: UserReportPageProps) => {
  const [userReportSearchQuery, setUserReportSearchQuery] = React.useState(location.query);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const searchQuery = makeUserSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const initialValues = {};

  const handleReset = () => {
    router.push({
      pathname: location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const handleSubmit = async (values: User, { setSubmitting }: FormikActions<User>) => {
    router.push({
      ...location,
      query: {
        ...userReportSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if (isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    setIsSubmit(true);
    action.apiFetch(ApiRoute.User, makeUserSearchQuery(query), {
      subrequests: USER_REPORTS_SUBRESOURCE_PATHS,
    });
  };

  return (
    <PageList title={`${t('common:user')} ${t('common:report')}`}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
                ? normalizeValues(initialValues)
                : normalizeValues(location.query)
            }
            onSubmit={handleSubmit}
            onReset={handleReset}
            render={(formikProps) => (
              <div>
                <UserOptionSearchFieldset
                  value={userReportSearchQuery}
                  onChange={(values) => {
                    setUserReportSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <UserReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : isSubmit ? users : []}
          loading={isFetching}
          params={makeUserSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: UserReportPageProps) => {
  const searchQuery = makeUserSearchQuery(query);
  const status = getRequestStatusByIri(state, ApiRoute.User, searchQuery);

  return {
    isFetching: !!status && status.pending > 0,
    pagination: getPaginationByIri(state, ApiRoute.User, searchQuery),
    status: state.statuses,
    users: getResourcesByIri<User>(state, ApiRoute.User, searchQuery, undefined, {
      subresources: USER_REPORTS_SUBRESOURCE_PATHS,
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      apiUpdate,
    },
    dispatch,
  ),
});

export default compose<UserReportPageProps, UserReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate(),
)(UserReportPage);
