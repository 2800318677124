import * as React from 'react';
import { ErrorBoundary, EnvironmentWarningAlert } from '@ucentric/react-components';
import { Layout } from 'antd';
import { useToggle } from 'react-use';

import MainHeader from 'app/components/layouts/MainHeader';
import SiderMenu from 'app/components/layouts/SiderMenu';
import StatusProgressBar from 'app/components/commons/StatusProgressBar';
import config from 'config';
import styles from './styles.module.css';
import { AppProps } from './properties';

const App = ({ children }: AppProps) => {
  const [isSiderMenuCollapsed, toggleSiderMenu] = useToggle(false);

  return (
    <>
      <Layout className={styles.root}>
        <StatusProgressBar />
        <SiderMenu collapsed={isSiderMenuCollapsed} collapsible trigger={null} />
        <Layout>
          <Layout.Header style={{ backgroundColor: '#fff', borderBottom: '1px solid #f3f3f3', padding: '0 2rem' }}>
            <MainHeader collapsed={isSiderMenuCollapsed} onCollapse={toggleSiderMenu} />
          </Layout.Header>
          <Layout.Content>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            {config.COMPANY.NAME} &copy; {new Date().getFullYear().toString()} Powered by {config.COPYRIGHT}
          </Layout.Footer>
        </Layout>
      </Layout>

      {config.ENABLE_ENVIRONMENT_WARNING_ALERT && <EnvironmentWarningAlert />}
    </>
  );
};

export default App;
