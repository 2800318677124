// @todo the resolver is still extremely naive, currently it has to be declare every path for nested property
// eg. `corporationDetails.contactPoints` must have `corporationDetails` defined.

import config from 'config';

/*export const AFFILIATE_PROGRAMS_COMMISSION_CONFIGURATIONS_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAccount',
  'creator',
  'creator.customerAccount',
  'image',
];*/

export const ACTIVITY_SMS_HISTORY_SUBRESOURCE_PATHS = ['activity', 'activity.creator', 'inboundSMS', 'outboundSMS'];

export const AFFILIATE_PROGRAM_SUBRESOURCE_PATHS = ['coverImage', 'image', 'terms', 'terms.image', 'terms.coverImage'];

export const AFFILIATE_PROGRAMS_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAccount',
  'creator',
  'creator.customerAccount',
  'image',
];

export const APPLICATION_REQUEST_SUBRESOURCE_PATHS = [
  'activities',
  'activities.creator',
  'activities.messageAttachments',
  // 'addonServices',
  'acquiredFrom',
  // 'acquiredFrom.corporationDetails',
  'addresses',
  'agent',
  'agent.customerAccount',
  'contract',
  'creator',
  'creator.customerAccount',
  'customer',
  // 'customerRepresentative',
  'statusHistory',
  'supplementaryFiles',
];

export const APPLICATION_REQUESTS_SUBRESOURCE_PATHS = [];

export const APPLICATION_REQUEST_REPORTS_SUBRESOURCE_PATHS = [
  'acquiredFrom',
  'acquiredFrom.corporationDetails',
  // 'addresses',
  // 'agent',
  // 'agent.customerAccount',
  // 'creator',
  'customer',
  'customer.corporationDetails',
  'customer.corporationDetails.contactPoints',
  'customer.corporationDetails.employees',
  'customer.corporationDetails.employees.employee',
  'customer.corporationDetails.employees.employee.personDetails',
  'customer.corporationDetails.employees.employee.personDetails.contactPoints',
  'customer.corporationDetails.employees.employee.personDetails.identifiers',
  'customer.corporationDetails.identifiers',
  'customer.personDetails',
  'customer.personDetails.contactPoints',
  'customer.personDetails.identifiers',
  'promotion',
  // 'statusHistory',
  // 'supplementaryFiles',
  'tariffRate',
];

export const CAMPAIGN_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAccount',
  'creator',
  'creator.customerAccount',
  'expectationList',
  'expectationList.itemListElement',
  'file',
  'recipientLists',
  'recipientLists.itemListElement',
  'template',
];

export const CAMPAIGN_TEMPLATE_SUBRESOURCE_PATHS = ['agent', 'agent.customerAccount', 'creator', 'creator.customerAccount'];

export const APPLICATION_TEMPLATE_SUBRESOURCE_PATHS = ['agent', 'agent.customerAccount', 'creator', 'creator.customerAccount'];
export const CAMPAIGNS_SUBRESOURCE_PATHS = ['assignee', 'assignee.customerAccount', 'objective', 'template'];

export const COMMISSION_RATE_SUBRESOURCE_PATHS = ['agent', 'creator'];

export const CONTRACT_SUBRESOURCE_PATHS = [
  'actions',
  'actions.instrument',
  'actions.object',
  'actions.result',
  'addresses',
  'creator',
  'customer',
  'tariffRate',
  ...(config.PROFILE !== 'unionpower'
    ? ['billingInformation', 'consumptionHistories', 'financialHistories']
    : ['billingSummary']),
];

// TODO ADD back webservice for Union

export const ADVISORY_NOTICE_SUBRESOURCE_PATHS = ['file'];

export const ADVISORY_NOTICES_SUBRESOURCE_PATHS = ['file'];

export const CREDITS_CONFIGURATIONS_SUBRESOURCE_PATHS = ['category'];

export const CREDITS_SCHEMES_SUBRESOURCE_PATHS = ['configuration'];

export const CUSTOMER_ACCOUNT_FACTSHEET_SUBRESOURCE_PATHS = [
  'activities',
  'activities.creator',
  'activities.messageAttachments',
  'addresses',
  'addresses.address',
  'agent',
  'agent.customerAccount',
  'applicationRequests',
  'contracts',
  'contracts.actions',
  'contracts.addresses',
  ...(config.PROFILE !== 'unionpower'
    ? ['contracts.billingInformation', 'contracts.consumptionHistories', 'contracts.financialHistories']
    : ['contracts.billingSummary']),
  'contracts.customer',
  'contracts.pointCreditsActions',
  'contracts.pointCreditsActions.creditsTransaction',
  'contracts.pointCreditsActions.instrument',
  'contracts.pointCreditsActions.instrument.customer',
  'contracts.pointCreditsActions.instrument.object',
  'contracts.pointCreditsActions.object',
  'contracts.pointCreditsActions.scheme',
  // 'corporationDetails',
  // 'corporationDetails.contactPoints',
  // 'corporationDetails.employees',
  // 'corporationDetails.employees.employee',
  // 'corporationDetails.employees.employee.personDetails',
  // 'corporationDetails.employees.employee.personDetails.contactPoints',
  // 'corporationDetails.employees.employee.personDetails.identifiers',
  // 'corporationDetails.employees.employee.user',
  // 'corporationDetails.identifiers',
  'creator',
  'creator.customerAccount',
  'image',
  'notes',
  'notes.files',
  // 'partnerDetails',
  // 'partnerDetails.tariffRateList',
  // 'partnerDetails.tariffRateList.itemListElement',
  // 'personDetails',
  // 'personDetails.contactPoints',
  // 'personDetails.identifiers',
  'relationships',
  'relationships.contracts',
  'relationships.from',
  'relationships.from.addresses',
  'relationships.to',
  'relationships.to.addresses',
  'supplementaryFiles',
  'user',
];

export const CUSTOMER_ACCOUNT_SUBRESOURCE_PATHS = [
  'activities',
  'activities.creator',
  'activities.messageAttachments',
  'addresses',
  'addresses.address',
  // 'applicationRequests',
  'agent',
  'agent.customerAccount',
  'contracts',
  'contracts.actions',
  // ...(config.PROFILE !== 'unionpower'
  //   ? ['contracts.billingInformation', 'contracts.consumptionHistories', 'contracts.financialHistories']
  //   : ['contracts.billingSummary']),
  'contracts.customer',
  'creator',
  'creator.customerAccount',
  'image',
  'notes',
  'notes.files',
  // 'partnerDetails',
  // 'partnerDetails.tariffRateList',
  // 'partnerDetails.tariffRateList.itemListElement',
  // 'personDetails',
  // 'personDetails.contactPoints',
  // 'personDetails.identifiers',
  'relationships',
  'relationships.contracts',
  'relationships.from',
  'relationships.from.addresses',
  'relationships.to',
  'relationships.to.addresses',
  'supplementaryFiles',
  'user',
];

export const CUSTOMER_ACCOUNT_PARTNER_SUBRESOURCE_PATHS = [
  'partnerDetails',
  'partnerDetails.tariffRateList',
  'partnerDetails.tariffRateList.itemListElement',
];

export const CUSTOMER_ACCOUNTS_SUBRESOURCE_PATHS = [
  // 'corporationDetails',
  // 'corporationDetails.contactPoints',
  // 'corporationDetails.employees',
  // 'corporationDetails.employees.employee',
  // 'corporationDetails.employees.employee.personDetails',
  // 'corporationDetails.identifiers',
  // 'image',
  // 'partnerDetails',
  // 'partnerDetails.administrator',
  // 'partnerDetails.administrator.customerAccount',
  // 'partnerDetails.administrator.customerAccount.personDetails',
  // 'personDetails',
  // 'personDetails.contactPoints',
  // 'personDetails.identifiers',
];

export const DEPARTMENT_SUBRESOURCE_PATHS = ['children', 'parent', 'roles'];

export const LEAD_REPORT_SUBRESOURCE_PATHS = ['addresses', 'notes', 'notes.files', 'tariffRate'];

export const LEAD_SUBRESOURCE_PATHS = [
  'acquiredFrom',
  'acquiredFrom.corporationDetails',
  'activities',
  'activities.creator',
  'activities.creator.customerAccount',
  'activities.creator.customerAccount.personDetails',
  'activities.messageAttachments',
  'addresses',
  'agent',
  'agent.customerAccount',
  // 'corporationDetails',
  // 'corporationDetails.contactPoints',
  // 'corporationDetails.identifiers',
  'creator',
  'creator.customerAccount',
  'notes',
  'notes.files',
  // 'personDetails',
  // 'personDetails.contactPoints',
  // 'personDetails.identifiers',
  // 'tariffRate',
];

export const LEADS_SUBRESOURCE_PATHS = [
  'assignee',
  'assignee.customerAccount',
  'assignees',
  'assignees.customerAccount',
  // 'corporationDetails',
  // 'corporationDetails.contactPoints',
  // 'corporationDetails.identifiers',
  // 'creator',
  // 'personDetails',
  // 'personDetails.contactPoints',
  // 'personDetails.identifiers',
];

export const MAINTENANCE_CONFIGURATION_SUBRESOURCE_PATHS = [];

export const MAINTENANCE_CONFIGURATIONS_SUBRESOURCE_PATHS = [];

export const MESSAGE_TEMPLATE_SUBRESOURCE_PATHS = ['messageAttachments', 'recipients'];

export const PROFILE_SUBRESOURCE_PATHS = ['roleProfileModules'];

export const PROFILES_SUBRESOURCE_PATHS = ['roleProfileModules'];

export const ROLE_SUBRESOURCE_PATHS = ['users'];

export const OFFER_SUBRESOURCE_PATHS = ['category', 'image'];

export const OFFERS_SUBRESOURCE_PATHS = ['category', 'image', 'seller'];

export const OFFER_CATALOG_SUBRESOURCE_PATHS = [
  // 'coverImage',
  // 'image',
  'itemListElement',
  'itemListElement.item',
  'itemListElement.item.category',
  'itemListElement.item.image',
  'itemListElement.item.seller',
  'itemListElement.serialNumbers',
];

export const OFFER_CATALOGS_SUBRESOURCE_PATHS = ['image'];

export const ORDER_SUB_RESOURCE_PATHS = [
  'customer',
  'items',
  'items.offerListItem',
  'items.offerListItem.item',
  'items.offerListItem.isBasedOn',
  'items.offerListItem.isBasedOn.item',
  'items.offerListItem.isBasedOn.item.seller',
];

export const QUOTATION_SUBRESOURCE_PATHS = [
  'addresses',
  'addresses.addresses',
  'agent',
  'agent.customerAccount',
  'contractDurations',
  'corporationDetails',
  'corporationDetails.contactPoints',
  'corporationDetails.identifiers',
  'creator',
  'creator.customerAccount',
  'customer',
  'file',
  'notes',
  'offers',
  'offers.duration',
  'personDetails',
  'personDetails.contactPoints',
  'personDetails.identifiers',
  'quotation',
  'quotation.acceptedOffer',
];

export const QUOTATION_PRICE_CONFIGURATION_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAccount',
  'creator',
  'creator.customerAccount',
];

export const ORDERS_SUBRESOURCE_PATHS = ['customer', 'items', 'object'];

export const POINT_TRANSACTION_HISTORY_PATHS = ['customer', 'instrument', 'object', 'object.pointCreditsActions', 'scheme'];

export const PROMOTION_SUBRESOURCE_PATHS = ['agent', 'category', 'creator', 'page', 'page.coverImage', 'page.image'];

export const RELATIONSHIP_SUBRESOURCE_PATHS = ['contracts', 'from', 'to'];

export const SERVICE_LEVEL_AGREEMENT_PATHS = ['ticketCategories', 'ticketTypes'];

export const TARIFF_RATE_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAccount',
  'creator',
  'creator.customerAccount',
  'page',
  'page.coverImage',
  'page.image',
  'terms',
  'terms.addonServiceList',
  'terms.addonServiceList.itemListElement',
  'terms.addonServiceList.itemListElement.item',
  'terms.freeGiftList',
  'terms.freeGiftList.itemListElement',
  'terms.freeGiftList.itemListElement.item',
];

export const TICKET_SUBRESOURCE_PATHS = [
  'acquiredFrom',
  'acquiredFrom.corporationDetails',
  'agent',
  'agent.customerAccount',
  'activities',
  'activities.creator',
  'activities.creator.customerAccount',
  'activities.creator.customerAccount.personDetails',
  'activities.messageAttachments',
  'contract',
  'creator',
  'creator.customerAccount',
  'customer',
  'customer.corporationDetails',
  'customer.corporationDetails.contactPoints',
  'customer.corporationDetails.identifiers',
  'customer.personDetails',
  'customer.personDetails.contactPoints',
  'customer.personDetails.identifiers',
  'notes',
  'notes.files',
  'personDetails',
  'personDetails.contactPoints',
  'personDetails.identifiers',
  'serviceLevelAgreement',
  'supplementaryFiles',
];

export const TICKETS_SUBRESOURCE_PATHS = [
  'assignee',
  'assignee.customerAccount',
  'category',
  'contract',
  'customer',
  'customer.corporationDetails.contactPoints',
  'customer.corporationDetails',
  'customer.corporationDetails.identifiers',
  'customer.personDetails',
  'customer.personDetails.contactPoints',
  'customer.personDetails.identifiers',
  'personDetails',
  'personDetails.contactPoints',
  'personDetails.identifiers',
  'subcategory',
  'type',
];

export const TICKET_CATEGORY_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAcount',
  'creator',
  'creator.customerAccount',
  // 'ticketTypes',
];

export const TICKET_CATEGORIES_SUBRESOURCE_PATHS = ['children', 'children.ticketTypes', 'ticketTypes'];

export const TICKET_TYPE_SUBRESOURCE_PATHS = [
  'agent',
  'agent.customerAcount',
  'creator',
  'creator.customerAccount',
  // 'ticketCategories',
];

export const TICKET_TYPES_SUBRESOURCE_PATHS = ['ticketCategories'];

export const USER_SUBRESOURCE_PATHS = [
  'customerAccount',
  'customerAccount.agent',
  'customerAccount.agent.customerAccount',
  'customerAccount.creator',
  'customerAccount.creator.customerAccount',
];

export const USERS_SUBRESOURCE_PATHS = ['customerAccount', 'customerAccount.personDetails'];

export const USER_REPORTS_SUBRESOURCE_PATHS = ['customerAccount', 'customerAccount.personDetails'];
