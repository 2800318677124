/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import { Icon, Layout, Menu } from 'antd';
import { Link } from 'found';
import { ModulePermission, ModuleType } from '@ucentric/constants';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getLoggedInUser } from '@ucentric/redux';
import { userRoleProfileModulePermissionValidator } from '@ucentric/validator';
import { url } from '@ucentric/utils';
import { withI18n } from 'react-i18next';

import config from 'config';
import styles from './styles.module.css';
import { RootState } from 'app/redux/store';
import { SiderMenuOuterProps, SiderMenuProps } from './properties';

const SiderMenu = ({
  defaultNS,
  dispatch,
  i18nOptions,
  location,
  loggedInUser,
  reportNS,
  t,
  tReady,
  ...props
}: SiderMenuProps) => {
  if (!loggedInUser) {
    return null;
  }

  const menuItems = [
    {
      // disabled: !userRoleProfileModulePermissionValidator(loggedInUser, {
      //   moduleType: ModuleType.Dashboard,
      //   permissions: [ModulePermission.Read],
      // }),
      disabled: false,
      icon: 'dashboard',
      title: t('common:dashboard'),
      to: '/home',
    },
    {
      disabled: true,
      icon: 'file-text',
      title: t('common:template_plural'),
      to: '/templates',
    },
    {
      disabled: !userRoleProfileModulePermissionValidator(loggedInUser, {
        moduleType: ModuleType.Administration,
        permissions: [ModulePermission.Read],
      }),
      icon: 'team',
      title: t('common:administration'),
      to: '/administration',
    },
    {
      disabled: !userRoleProfileModulePermissionValidator(loggedInUser, {
        moduleType: ModuleType.Sales,
        permissions: [ModulePermission.Read],
      }),
      icon: 'pay-circle-o',
      title: t('common:sales'),
      to: '/sales',
    },
    {
      disabled:
        !userRoleProfileModulePermissionValidator(loggedInUser, {
          moduleType: ModuleType.Services,
          permissions: [ModulePermission.Read],
        }) || !!config.OLD_ADMIN_URL,
      icon: 'customer-service',
      title: t('common:service_plural'),
      to: '/services',
    },
    {
      disabled:
        !userRoleProfileModulePermissionValidator(loggedInUser, {
          moduleType: ModuleType.Marketing,
          permissions: [ModulePermission.Read],
        }) || !!config.OLD_ADMIN_URL,
      icon: 'line-chart',
      title: t('common:marketing'),
      to: '/marketing',
    },
    {
      disabled:
        !userRoleProfileModulePermissionValidator(loggedInUser, {
          moduleType: ModuleType.CustomerPortal,
          permissions: [ModulePermission.Read],
        }) || !!config.OLD_ADMIN_URL,
      icon: 'key',
      title: t('common:customerPortal'),
      to: '/customer-portal',
    },
    {
      disabled:
        !userRoleProfileModulePermissionValidator(loggedInUser, {
          moduleType: ModuleType.MessageCentre,
          permissions: [ModulePermission.Read],
        }) || !config.ENABLE_MESSAGE_CENTRE,
      icon: 'message',
      title: t('common:messageCentre'),
      to: '/message-centre',
    },
    {
      disabled: !config.ENABLE_REPORTS,
      icon: 'profile',
      title: t('common:report_plural'),
      to: '/reports',
    },
    {
      disabled: !config.ENABLE_REPORTS,
      icon: 'folder-open',
      title: t('common:report', { context: 'history' }),
      to: '/reports/history',
    },
  ];

  return (
    <Layout.Sider className={styles.root} width={255} {...props}>
      <div className={styles.logoPanel}>
        <Link to="/">
          <img alt={config.COMPANY.NAME} src={config.COMPANY.LOGO_URL} className={styles.logo} />
        </Link>
      </div>

      <Menu key="sider-menu" mode="inline" selectedKeys={url.pathnameToPathnames(location.pathname)} className={styles.menu}>
        {menuItems
          .filter((menuItem) => !menuItem.hidden)
          .map((menuItem) => (
            <Menu.Item disabled={menuItem.disabled || !menuItem.to} key={menuItem.to}>
              <Link to={menuItem.to}>
                {menuItem.icon && <Icon type={menuItem.icon} />} {menuItem.title}
              </Link>
            </Menu.Item>
          ))}
      </Menu>
    </Layout.Sider>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.found.match.location,
  loggedInUser: getLoggedInUser(state),
});

export default compose<SiderMenuProps, SiderMenuOuterProps>(
  connect(mapStateToProps),
  withI18n(),
  React.memo,
)(SiderMenu);
