import * as React from 'react';
import cx from 'clsx';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Progress } from 'antd';

import styles from './styles.module.css';
import { StatusProgressBarProps } from './properties';

function calculateProgress({ done, error, pending, total }) {
  return ((done + error - pending) / (total - pending)) * 100 || 100;
}

const StatusProgressBar = ({ statuses }: StatusProgressBarProps) => (
  <Progress
    percent={calculateProgress(statuses)}
    showInfo={false}
    status="active"
    className={cx(styles.root, { [styles.done]: isEmpty(statuses) || calculateProgress(statuses) === 100 })}
  />
);

const mapStateToProps = ({ statuses }) => ({
  statuses,
});

export default connect(mapStateToProps)(StatusProgressBar);
