import { PartnerStatus } from '@ucentric/constants';
import { apiResourceValidation } from '@ucentric/utils';

export function getPartnerStatus(partner?: Partner) {
  if (!partner) {
    return PartnerStatus.Inactive;
  }

  return apiResourceValidation.isPartnerActive(partner) ? PartnerStatus.Active : PartnerStatus.Inactive;
}
