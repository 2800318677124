/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import loadable from 'loadable-components';
import { Logout } from '@ucentric/react-components';
import { hotRouteConfig, makeRouteConfig, Redirect, Route } from 'found';

import App from 'app/components/layouts/App';
import ApplicationRequestReportPage from 'app/pages/ApplicationRequestReportPage';
import AuthorizedRoute from 'app/components/commons/AuthorizedRoute';
import CustomerReportPage from 'app/pages/CustomerReportPage';
import ContractReportPage from 'app/pages/ContractReportPage';
import LeadReportPage from 'app/pages/LeadReportPage';
import UserReportPage from 'app/pages/UserReportPage';
import PointTransactionReportPage from 'app/pages/PointTransactionReportPage';
import TicketReportPage from 'app/pages/TicketReportPage';
import {
  fetchAdvisoryNotice,
  fetchApplicationTemplate,
  fetchAffiliateProgram,
  fetchApplicationRequest,
  fetchCampaign,
  fetchCampaignExpectation,
  fetchCampaignObjective,
  fetchCampaignTemplate,
  // fetchCreditsCategory,
  // fetchCreditsConfiguration,
  fetchCreditsScheme,
  fetchCustomerAccount,
  fetchCustomerAccountContract,
  fetchCustomerAccountFactsheet,
  fetchCustomerAccountPartner,
  fetchCustomerFromQuery,
  fetchDataForApplicationRequestDetailsPage,
  // fetchDepartment,
  fetchFaqConfiguration,
  fetchFaqConfigurationCategory,
  fetchLead,
  fetchMaintenanceConfiguration,
  fetchMerchant,
  fetchMessageTemplate,
  fetchOffer,
  fetchOfferCatalog,
  fetchOfferCategory,
  fetchOrder,
  fetchPartnershipReports,
  fetchProfile,
  fetchPromotion,
  fetchQuotation,
  fetchQuotationPriceConfiguration,
  fetchRole,
  fetchServiceLevelAgreement,
  fetchSourceList,
  fetchTariffRate,
  fetchTicket,
  fetchTicketCategory,
  fetchTicketType,
  fetchUser,
  fetchValidationRule,
  requestBridgeAuthToken,
} from 'app/helpers';

const ActivitySmsHistoryListPage = loadable(() => import('app/pages/ActivitySmsHistoryListPage'));
const AdminUserDetailsPage = loadable(() => import('app/pages/AdminUserDetailsPage'));
const AdminUserListPage = loadable(() => import('app/pages/AdminUserListPage'));
const AdministrationPage = loadable(() => import('app/pages/AdministrationPage'));
const AdvisoryNoticeListPage = loadable(() => import('app/pages/AdvisoryNoticeListPage'));
const AdvisoryNoticeDetailsPage = loadable(() => import('app/pages/AdvisoryNoticeDetailsPage'));
const AffiliateProgramCommissionConfigurationDetailsPage = loadable(() =>
  import('app/pages/AffiliateProgramCommissionConfigurationDetailsPage'),
);
const AffiliateProgramConfigurationLayout = loadable(() => import('app/components/layouts/AffiliateProgramConfigurationLayout'));
const AffiliateProgramDetailsPage = loadable(() => import('app/pages/AffiliateProgramDetailsPage'));
const AffiliateProgramListPage = loadable(() => import('app/pages/AffiliateProgramListPage'));
const ApplicationRequestConfigurationLayout = loadable(() =>
  import('app/components/layouts/ApplicationRequestConfigurationLayout'),
);
const ApplicationRequestDetailsPage = loadable(() => import('app/pages/ApplicationRequestDetailsPage'));
const ApplicationRequestStatusHistoryListPage = loadable(() => import('app/pages/ApplicationRequestStatusHistoryListPage'));
const ApplicationRequestListPage = loadable(() => import('app/pages/ApplicationRequestListPage'));
const ApplicationRequestValidationRuleListPage = loadable(() => import('app/pages/ApplicationRequestValidationRuleListPage'));
const ApplicationRequestValidationRuleDetailsPage = loadable(() =>
  import('app/pages/ApplicationRequestValidationRuleDetailsPage'),
);
const BridgeLoginPage = loadable(() => import('app/pages/BridgeLoginPage'));
const CampaignConfigurationLayout = loadable(() => import('app/components/layouts/CampaignConfigurationLayout'));
const CampaignDetailsPage = loadable(() => import('app/pages/CampaignDetailsPage'));
const CampaignExpectationDetailsPage = loadable(() => import('app/pages/CampaignExpectationDetailsPage'));
const CampaignExpectationListPage = loadable(() => import('app/pages/CampaignExpectationListPage'));
const CampaignListPage = loadable(() => import('app/pages/CampaignListPage'));
const CampaignObjectiveDetailsPage = loadable(() => import('app/pages/CampaignObjectiveDetailsPage'));
const CampaignObjectiveListPage = loadable(() => import('app/pages/CampaignObjectiveListPage'));
const CampaignTemplateDetailsPage = loadable(() => import('app/pages/CampaignTemplateDetailsPage'));
const CampaignTemplateListPage = loadable(() => import('app/pages/CampaignTemplateListPage'));
const CommissionRateDetailsPage = loadable(() => import('app/pages/CommissionRateDetailsPage'));
const CommissionRateListPage = loadable(() => import('app/pages/CommissionRateListPage'));
// const CreditsCategoryDetailsPage = loadable(() => import('app/pages/CreditsCategoryDetailsPage'));
// const CreditsCategoryListPage = loadable(() => import('app/pages/CreditsCategoryListPage'));
// const CreditsConfigurationDetailsPage = loadable(() => import('app/pages/CreditsConfigurationDetailsPage'));
// const CreditsConfigurationListPage = loadable(() => import('app/pages/CreditsConfigurationListPage'));
const CreditsSchemeDetailsPage = loadable(() => import('app/pages/CreditsSchemeDetailsPage'));
const CreditsSchemeListPage = loadable(() => import('app/pages/CreditsSchemeListPage'));
const CustomerAccountContractDetailsPage = loadable(() => import('app/pages/CustomerAccountContractDetailsPage'));
const CustomerAccountDetailsPage = loadable(() => import('app/pages/CustomerAccountDetailsPage'));
const CustomerAccountFactsheetPage = loadable(() => import('app/pages/CustomerAccountFactsheetPage'));
const CustomerAccountListPage = loadable(() => import('app/pages/CustomerAccountListPage'));
const CustomerAccountRelationshipReportPage = loadable(() => import('app/pages/CustomerAccountRelationshipReportPage'));
const CustomerPortalPage = loadable(() => import('app/pages/CustomerPortalPage'));
const CustomerSmsSatisfactionReportPage = loadable(() => import('app/pages/CustomerSmsSatisfactionReportPage'));
const DashboardPage = loadable(() => import('app/pages/DashboardPage'));
const ApplicationDashboardPage = loadable(() => import('app/pages/ApplicationDashboardPage'));
const LeadDashboardPage = loadable(() => import('app/pages/LeadDashboardPage'));
// const DepartmentListPage = loadable(() => import('app/pages/DepartmentListPage'));
// const DepartmentDetailsPage = loadable(() => import('app/pages/DepartmentDetailsPage'));
const EmployeeSmsRatingReportPage = loadable(() => import('app/pages/EmployeeSmsRatingReportPage'));
const ApplicationTemplateListPage = loadable(() => import('app/pages/ApplicationTemplateListPage'));
const ApplicationTemplateDetailsPage = loadable(() => import('app/pages/ApplicationTemplateDetailsPage'));
const FaqConfigurationListPage = loadable(() => import('app/pages/FAQConfigurationListPage'));
const FaqConfigurationCategoryDetailsPage = loadable(() => import('app/pages/FAQConfigurationCategoryDetailsPage'));
const FAQConfigurationCategoryListPage = loadable(() => import('app/pages/FAQConfigurationCategoryListPage'));
const FaqConfigurationDetailsPage = loadable(() => import('app/pages/FAQConfigurationDetailsPage'));
const FAQConfigurationLayout = loadable(() => import('app/components/layouts/FAQConfigurationLayout'));
const ForgotPasswordPage = loadable(() => import('app/pages/ForgotPasswordPage'));
const LeadDetailsPage = loadable(() => import('app/pages/LeadDetailsPage'));
const LeadImportPage = loadable(() => import('app/pages/LeadImportPage'));
const LeadListPage = loadable(() => import('app/pages/LeadListPage'));
const LoginPage = loadable(() => import('app/pages/LoginPage'));
const LoyaltyConfigurationLayout = loadable(() => import('app/components/layouts/LoyaltyConfigurationLayout'));
const MarketingPage = loadable(() => import('app/pages/MarketingPage'));
const MaintenanceConfigurationListPage = loadable(() => import('app/pages/MaintenanceConfigurationListPage'));
const MaintenanceConfigurationDetailsPage = loadable(() => import('app/pages/MaintenanceConfigurationDetailsPage'));
const MaintenanceMessagePage = loadable(() => import('app/pages/MaintenanceMessagePage'));
const MerchantDetailsPage = loadable(() => import('app/pages/MerchantDetailsPage'));
const MerchantListPage = loadable(() => import('app/pages/MerchantListPage'));
const MessageCentrePage = loadable(() => import('app/pages/MessageCentrePage'));
const MessageTemplateListPage = loadable(() => import('app/pages/MessageTemplateListPage'));
const MessageTemplateDetailsPage = loadable(() => import('app/pages/MessageTemplateDetailsPage'));
const OfferCatalogDetailsPage = loadable(() => import('app/pages/OfferCatalogDetailsPage'));
const OfferCatalogListPage = loadable(() => import('app/pages/OfferCatalogListPage'));
const OfferCategoryDetailsPage = loadable(() => import('app/pages/OfferCategoryDetailsPage'));
const OfferCategoryListPage = loadable(() => import('app/pages/OfferCategoryListPage'));
const OfferDetailsPage = loadable(() => import('app/pages/OfferDetailsPage'));
const QuotationPriceConfigurationDetailsPage = loadable(() => import('app/pages/QuotationPriceConfigurationDetailsPage'));
const QuotationPriceConfigurationListPage = loadable(() => import('app/pages/QuotationPriceConfigurationListPage'));
const OfferListPage = loadable(() => import('app/pages/OfferListPage'));
const OrderDetailsPage = loadable(() => import('app/pages/OrderDetailsPage'));
const OrderReportPage = loadable(() => import('app/pages/OrderReportPage'));
const CreditsActionListPage = loadable(() => import('app/pages/CreditsActionListPage'));
const OrderListPage = loadable(() => import('app/pages/OrderListPage'));
const PartnerDetailsPage = loadable(() => import('app/pages/PartnerDetailsPage'));
const PartnerListPage = loadable(() => import('app/pages/PartnerListPage'));
const PointCreditsExchangeRatePage = loadable(() => import('app/pages/PointCreditsExchangeRatePage'));
const ProfileDetailsPage = loadable(() => import('app/pages/ProfileDetailsPage'));
const ProfileListPage = loadable(() => import('app/pages/ProfileListPage'));
const QuotationDetailsPage = loadable(() => import('app/pages/QuotationDetailsPage'));
const QuotationListPage = loadable(() => import('app/pages/QuotationListPage'));
const PromotionListPage = loadable(() => import('app/pages/PromotionListPage'));
const PromotionDetailsPage = loadable(() => import('app/pages/PromotionDetailsPage'));
const ReportPage = loadable(() => import('app/pages/ReportPage'));
const ReportsHistoryListPage = loadable(() => import('app/pages/ReportsHistoryListPage'));
const RoleDetailsPage = loadable(() => import('app/pages/RoleDetailsPage'));
const RoleListPage = loadable(() => import('app/pages/RoleListPage'));
const SalesPage = loadable(() => import('app/pages/SalesPage'));
const ServiceLevelAgreementDetailsPage = loadable(() => import('app/pages/ServiceLevelAgreementDetailsPage'));
const ServiceLevelAgreementListPage = loadable(() => import('app/pages/ServiceLevelAgreementListPage'));
const ServicePage = loadable(() => import('app/pages/ServicePage'));
const SmsFeedbackConfigurationLayout = loadable(() => import('app/components/layouts/SmsFeedbackConfigurationLayout'));
const SourceListDetailsPage = loadable(() => import('app/pages/SourceListDetailsPage'));
const SourceListListPage = loadable(() => import('app/pages/SourceListListPage'));
const StatusFileReportPage = loadable(() => import('app/pages/StatusFileReportPage'));
const TariffRateConfigurationLayout = loadable(() => import('app/components/layouts/TariffRateConfigurationLayout'));
const TariffCardDisplayConfiguration = loadable(() => import('app/pages/TariffCardDisplayConfiguration'));
const TariffRateDetailsPage = loadable(() => import('app/pages/TariffRateDetailsPage'));
const TariffRateListPage = loadable(() => import('app/pages/TariffRateListPage'));
const TicketCategoryDetailsPage = loadable(() => import('app/pages/TicketCategoryDetailsPage'));
const TicketCategoryListPage = loadable(() => import('app/pages/TicketCategoryListPage'));
const TicketConfigurationLayout = loadable(() => import('app/components/layouts/TicketConfigurationLayout'));
const TicketDashboardPage = loadable(() => import('app/pages/TicketsDashboardPage'));
const TicketDetailsPage = loadable(() => import('app/pages/TicketDetailsPage'));
const TicketListPage = loadable(() => import('app/pages/TicketListPage'));
const TicketTypeDetailsPage = loadable(() => import('app/pages/TicketTypeDetailsPage'));
const TicketTypeListPage = loadable(() => import('app/pages/TicketTypeListPage'));
const UserDetailsPage = loadable(() => import('app/pages/UserDetailsPage'));
const UserListPage = loadable(() => import('app/pages/UserListPage'));

const routeConfig = hotRouteConfig(
  makeRouteConfig(
    <Route>
      <Redirect from="/sales/quotations/configuration" to="/sales/quotations/configuration/price-configurations" />
      <Redirect
        from="/marketing/affiliate-programs/configuration"
        to="/marketing/affiliate-programs/configuration/distribution-rates"
      />
      <Redirect from="/sales/applications/configurations" to="/sales/applications/configurations/validations" />
      <Redirect from="/marketing/tariff-rates/configuration" to="/marketing/tariff-rates/configuration/advisory-notices" />

      <Redirect from="/marketing/campaigns/configuration" to="/marketing/campaigns/configuration/expectations" />
      <Redirect from="/marketing/loyalty" to="/marketing/loyalty/products" />
      <Redirect from="/marketing/loyalty/configuration" to="/marketing/loyalty/configuration/credits-schemes" />
      <Redirect from="/services/cases/configuration" to="/services/cases/configuration/categories" />
      <Redirect from="/services/customers/:accountNumber/contracts" to="/services/customers/:accountNumber" />
      <Redirect from="/services/sms-feedbacks/reports" to="/services/sms-feedbacks/reports/customer-satisfaction" />
      <Redirect from="/" to="/home" />
      <Route Component={ForgotPasswordPage} path="/forgot-password" />
      <Route Component={BridgeLoginPage} getData={requestBridgeAuthToken()} path="/bridge/login" />
      <Route Component={LoginPage} path="/login" />
      <Route Component={Logout} path="/logout" />
      <Route Component={MaintenanceMessagePage} path="/maintenance-message" />

      <AuthorizedRoute Component={App} path="/">
        <Route path="home">
          <Route Component={DashboardPage} />
          <Route Component={ApplicationDashboardPage} path="application-requests" />
          <Route Component={LeadDashboardPage} path="lead" />
          <Route Component={TicketDashboardPage} path="tickets" />
        </Route>
        <Route path="administration">
          <Route Component={AdministrationPage} />
          {/*
          <Route path="departments">
            <Route Component={DepartmentListPage} />
            <Route Component={DepartmentDetailsPage} path="create" />
            <Route path=":id" Component={DepartmentDetailsPage} getData={fetchDepartment} />
          </Route>
          */}
          <Route path="profiles">
            <Route Component={ProfileListPage} />
            <Route Component={ProfileDetailsPage} path="create" />
            <Route path=":id" Component={ProfileDetailsPage} getData={fetchProfile} />
          </Route>
          <Route path="roles">
            <Route Component={RoleListPage} />
            <Route Component={RoleDetailsPage} path="create" />
            <Route path=":id" Component={RoleDetailsPage} getData={fetchRole} />
          </Route>
          <Route path="users">
            <Route Component={AdminUserListPage} />
            <Route path="create" Component={AdminUserDetailsPage} />
            <Route path=":id" Component={AdminUserDetailsPage} getData={fetchUser} />
          </Route>
          <Route path="maintenance-configurations">
            <Route Component={MaintenanceConfigurationListPage} />
            <Route path="create" Component={MaintenanceConfigurationDetailsPage} />
            <Route path=":id" Component={MaintenanceConfigurationDetailsPage} getData={fetchMaintenanceConfiguration} />
          </Route>
          <Route path="web-service">
            <Route Component={StatusFileReportPage} path="status-files" />
          </Route>
        </Route>

        {/* Marketing Route here */}
        <Route path="marketing">
          <Route Component={MarketingPage} />

          <Route path="affiliate-programs">
            <Route Component={AffiliateProgramListPage} />
            <Route path="create" Component={AffiliateProgramDetailsPage} />
            <Route path="configuration" Component={AffiliateProgramConfigurationLayout}>
              <Route Component={AffiliateProgramCommissionConfigurationDetailsPage} path="distribution-rates" />
            </Route>
            <Route path=":id" Component={AffiliateProgramDetailsPage} getData={fetchAffiliateProgram()} />
          </Route>

          <Route path="campaigns">
            <Route Component={CampaignConfigurationLayout} path="configuration">
              <Route path="expectations">
                <Route Component={CampaignExpectationListPage} />
                <Route Component={CampaignExpectationDetailsPage} path="create" />
                <Route path=":id" Component={CampaignExpectationDetailsPage} getData={fetchCampaignExpectation()} />
              </Route>

              <Route path="objectives">
                <Route Component={CampaignObjectiveListPage} />
                <Route Component={CampaignObjectiveDetailsPage} path="create" />
                <Route path=":id" Component={CampaignObjectiveDetailsPage} getData={fetchCampaignObjective()} />
              </Route>
            </Route>

            <Route path="sources">
              <Route Component={SourceListListPage} />
              <Route Component={SourceListDetailsPage} path="create" />
              <Route Component={SourceListDetailsPage} path=":id" getData={fetchSourceList()} />
            </Route>

            <Route path="templates">
              <Route Component={CampaignTemplateListPage} />
              <Route Component={CampaignTemplateDetailsPage} path="create" />
              <Route path=":id" Component={CampaignTemplateDetailsPage} getData={fetchCampaignTemplate()} />
            </Route>

            <Route Component={CampaignListPage} />
            <Route Component={CampaignDetailsPage} path="create" />
            <Route Component={CampaignDetailsPage} path=":campaignNumber" getData={fetchCampaign()} />
          </Route>

          <Route path="leads">
            <Route Component={LeadListPage} />
            <Route Component={LeadImportPage} path="import" />
            <Route Component={LeadDetailsPage} path="create" />
            <Route Component={LeadDetailsPage} getData={fetchLead()} path=":leadNumber" />
          </Route>

          <Route path="loyalty">
            <Route Component={LoyaltyConfigurationLayout} path="configuration">
              {/*
              <Route path="credits-categories">
                <Route Component={CreditsCategoryListPage} />
                <Route Component={CreditsCategoryDetailsPage} path="create" />
                <Route path=":id" Component={CreditsCategoryDetailsPage} getData={fetchCreditsCategory()} />
              </Route>
              */}

              <Route path="credits-schemes">
                <Route Component={CreditsSchemeListPage} />
                <Route Component={CreditsSchemeDetailsPage} path="create" />
                <Route path=":id" Component={CreditsSchemeDetailsPage} getData={fetchCreditsScheme()} />
              </Route>

              {/*
              <Route path="credits-configurations">
                <Route Component={CreditsConfigurationListPage} />
                <Route Component={CreditsConfigurationDetailsPage} path="create" />
                <Route path=":id" Component={CreditsConfigurationDetailsPage} getData={fetchCreditsConfiguration()} />
              </Route>
              */}

              <Route path="product-categories">
                <Route Component={OfferCategoryListPage} />
                <Route Component={OfferCategoryDetailsPage} path="create" />
                <Route path=":categoryNumber" Component={OfferCategoryDetailsPage} getData={fetchOfferCategory()} />
              </Route>

              <Route path="merchants">
                <Route Component={MerchantListPage} />
                <Route Component={MerchantDetailsPage} path="create" />
                <Route path=":merchantNumber" Component={MerchantDetailsPage} getData={fetchMerchant()} />
              </Route>

              <Route Component={PointCreditsExchangeRatePage} path="exchange-rates" />
            </Route>

            <Route path="products">
              <Route Component={OfferListPage} />
              <Route Component={OfferDetailsPage} path="create" />
              <Route path=":id" Component={OfferDetailsPage} getData={fetchOffer()} />
            </Route>

            <Route path="product-catalogs">
              <Route Component={OfferCatalogListPage} />
              <Route Component={OfferCatalogDetailsPage} path="create" />
              <Route path=":id" Component={OfferCatalogDetailsPage} getData={fetchOfferCatalog()} />
            </Route>

            <Route path="orders">
              <Route Component={OrderListPage} />
              <Route path=":id" Component={OrderDetailsPage} getData={fetchOrder()} />
            </Route>

            <Route path="point-histories">
              <Route Component={CreditsActionListPage} />
            </Route>
          </Route>

          <Route path="promotions">
            <Route Component={PromotionListPage} />
            <Route Component={PromotionDetailsPage} path=":id" getData={fetchPromotion()} />
          </Route>

          <Route path="tariff-rates">
            <Route Component={TariffRateListPage} />
            {/* <Route Component={TariffRateDetailsPage} path="create" /> */}
            <Route Component={TariffRateDetailsPage} path=":tariffRateNumber" getData={fetchTariffRate()} />
            <Route Component={TariffRateConfigurationLayout} path="configuration">
              <Route path="advisory-notices">
                <Route Component={AdvisoryNoticeListPage} />
                <Route Component={AdvisoryNoticeDetailsPage} path="create" />
                <Route Component={AdvisoryNoticeDetailsPage} path=":id" getData={fetchAdvisoryNotice()} />
              </Route>
              <Route path="tariff-card-display-configuration">
                <Route Component={TariffCardDisplayConfiguration} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="sales">
          <Route Component={SalesPage} />

          <Route path="applications">
            <Route Component={ApplicationRequestListPage} />
            <Route
              Component={ApplicationRequestDetailsPage}
              getData={fetchDataForApplicationRequestDetailsPage()}
              path="create"
            />
            <Route
              Component={ApplicationRequestDetailsPage}
              getData={fetchApplicationRequest()}
              path=":applicationRequestNumber"
            />
            <Route Component={ApplicationRequestConfigurationLayout} path="configurations">
              <Route path="validations">
                <Route Component={ApplicationRequestValidationRuleListPage} />
                <Route path="create" Component={ApplicationRequestValidationRuleDetailsPage} />
                <Route path=":id" Component={ApplicationRequestValidationRuleDetailsPage} getData={fetchValidationRule} />
              </Route>
              <Route path="template">
                <Route Component={ApplicationTemplateListPage} />
                <Route path="create" Component={ApplicationTemplateDetailsPage} />
                <Route path=":id" Component={ApplicationTemplateDetailsPage} getData={fetchApplicationTemplate()} />
              </Route>
            </Route>
          </Route>

          <Route path="partners">
            <Route path="commissions">
              <Route Component={CommissionRateListPage} />
              <Route Component={CommissionRateDetailsPage} path="create" />
              <Route Component={CommissionRateDetailsPage} path=":id" />
            </Route>

            <Route Component={PartnerListPage} />
            <Route Component={PartnerDetailsPage} path="create" />
            <Route Component={PartnerDetailsPage} getData={fetchCustomerAccountPartner} path=":accountNumber" />
          </Route>

          <Route path="quotations">
            <Route Component={QuotationListPage} />
            <Route Component={QuotationDetailsPage} path="create" />
            <Route Component={QuotationDetailsPage} path=":quotationNumber" getData={fetchQuotation()} />
            <Route path="configuration">
              <Route path="price-configurations">
                <Route Component={QuotationPriceConfigurationListPage} />
                <Route Component={QuotationPriceConfigurationDetailsPage} path="create" />
                <Route
                  Component={QuotationPriceConfigurationDetailsPage}
                  path=":id"
                  getData={fetchQuotationPriceConfiguration()}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="reports">
          <Route Component={ReportPage} />
          <Route Component={ApplicationRequestReportPage} path="applications" />
          <Route Component={ApplicationRequestStatusHistoryListPage} path="status_history" />
          <Route Component={CustomerReportPage} path="customers" />
          <Route Component={ContractReportPage} path="contracts" />
          <Route Component={LeadReportPage} path="leads" />
          <Route Component={OrderReportPage} path="redemption-orders" />
          <Route Component={PointTransactionReportPage} path="point-transactions" />
          <Route Component={UserReportPage} path="users" />
          <Route Component={CustomerAccountRelationshipReportPage} path="customer-relationships" />
          <Route Component={TicketReportPage} path="cases" />
        </Route>
        <Route path="services">
          <Route Component={ServicePage} />

          <Route path="customers">
            <Route Component={CustomerAccountListPage} />
            <Route Component={CustomerAccountDetailsPage} path="create" />
            <Route Component={CustomerAccountDetailsPage} getData={fetchCustomerAccount} path=":accountNumber" />
            <Route
              Component={CustomerAccountFactsheetPage}
              getData={fetchCustomerAccountFactsheet}
              path=":accountNumber/overview"
            />
            <Route
              Component={CustomerAccountContractDetailsPage}
              getData={fetchCustomerAccountContract}
              path=":accountNumber/contracts/:contractNumber"
            />
          </Route>

          <Route path="cases">
            <Route Component={TicketConfigurationLayout} path="configuration">
              <Route path="categories">
                <Route Component={TicketCategoryListPage} />
                <Route path="create" Component={TicketCategoryDetailsPage} />
                <Route path=":id" Component={TicketCategoryDetailsPage} getData={fetchTicketCategory} />
              </Route>

              <Route path="types">
                <Route Component={TicketTypeListPage} />
                <Route path="create" Component={TicketTypeDetailsPage} />
                <Route path=":id" Component={TicketTypeDetailsPage} getData={fetchTicketType} />
              </Route>

              <Route path="slas">
                <Route Component={ServiceLevelAgreementListPage} />
                <Route path="create" Component={ServiceLevelAgreementDetailsPage} />
                <Route path=":id" Component={ServiceLevelAgreementDetailsPage} getData={fetchServiceLevelAgreement} />
              </Route>
            </Route>

            <Route Component={TicketListPage} />
            <Route Component={TicketDetailsPage} path="create" getData={fetchCustomerFromQuery()} />
            <Route Component={TicketDetailsPage} getData={fetchTicket()} path=":ticketNumber" />
          </Route>

          <Route path="sms-feedbacks">
            <Route Component={ActivitySmsHistoryListPage} />
            <Route Component={SmsFeedbackConfigurationLayout} path="reports">
              <Route path="customer-satisfaction" Component={CustomerSmsSatisfactionReportPage} />
              <Route path="employee-ratings" Component={EmployeeSmsRatingReportPage} />
            </Route>
          </Route>
        </Route>

        <Route path="customer-portal">
          <Route Component={CustomerPortalPage} />

          <Route path="users">
            <Route Component={UserListPage} />
            <Route path="create" Component={UserDetailsPage} />
            <Route path=":id" Component={UserDetailsPage} getData={fetchUser} />
          </Route>

          <Route Component={FAQConfigurationLayout} path="configuration">
            <Route path="faq">
              <Route Component={FaqConfigurationListPage} />
              <Route path="create" Component={FaqConfigurationDetailsPage} />
              <Route path=":id" Component={FaqConfigurationDetailsPage} getData={fetchFaqConfiguration()} />
            </Route>

            <Route path="categories">
              <Route Component={FAQConfigurationCategoryListPage} />
              <Route path="create" Component={FaqConfigurationCategoryDetailsPage} />
              <Route path=":id" Component={FaqConfigurationCategoryDetailsPage} getData={fetchFaqConfigurationCategory()} />
            </Route>
          </Route>
        </Route>

        <Route path="message-centre">
          <Route Component={MessageCentrePage} />
          <Route path="push-notifications">
            <Route Component={MessageTemplateListPage} />
            <Route path="configure" Component={MessageTemplateDetailsPage} />
            <Route path=":messageNumber" Component={MessageTemplateDetailsPage} getData={fetchMessageTemplate} />
          </Route>
        </Route>
        <Route path="reports">
          <Route path="history" Component={ReportsHistoryListPage} />
          <Route path=":type" Component={ReportsHistoryListPage} getData={fetchPartnershipReports} />
        </Route>
      </AuthorizedRoute>
    </Route>,
  ),
);

export default routeConfig;
