import * as React from 'react';
import moment from 'moment';
import { ApiRoute } from '@ucentric/constants';
import { Card } from 'antd';
import { Formik } from 'formik';
import { LeadOptionSearchFieldset, LeadReportTable, PageList } from '@ucentric/react-components';
import { apiFetch, apiUpdate, getPaginationByIri, getResourcesByIri, getRequestStatusByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get, filter, isEmpty, isObject, zipObject } from 'lodash';
import { translate } from 'react-i18next';

import styles from './styles.module.css';
import { LEAD_REPORT_SUBRESOURCE_PATHS } from 'app/constants';
import { LeadReportPageOuterProps, LeadReportPageProps, LeadReportPageState } from './properties';
import { RootState } from 'app/redux/store';

function makeLeadSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;

  return {
    itemsPerPage: 10,
    ...apiSearchQuery.leadSearchQuery(query),
    ...(!hasSorter ? { 'order[dateCreated]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',');
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }

      return value;
    }),
  );
}

const LeadReportPage = ({action, isFetching, leads, location, pagination, router, status, t}: LeadReportPageProps) => {
  const [leadReportSearchQuery, setLeadReportSearchQuery] = React.useState(location.query);

  const searchQuery = makeLeadSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const handleReset = () => {
    router.push({
      pathname:location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const handleSubmit = async (values: Lead, { setSubmitting }: FormikActions<Lead>) => {
    router.push({
      ...location,
      query: {
        ...leadReportSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if(isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    action.apiFetch(ApiRoute.Lead, makeLeadSearchQuery(query), {
      subrequests: LEAD_REPORT_SUBRESOURCE_PATHS,
    });
  };

  return (
    <PageList title={t('common:lead') + ' ' + t('common:report')}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
              ? normalizeValues({})
              : normalizeValues(location.query)
            }
            onReset={handleReset}
            onSubmit={handleSubmit}
            render={(formikProps) => (
              <div>
                <LeadOptionSearchFieldset
                  value={leadReportSearchQuery}
                  onChange={(values) => {
                    setLeadReportSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <LeadReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : leads}
          loading={isFetching}
          params={makeLeadSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: LeadReportPageProps) => {
  const searchQuery = makeLeadSearchQuery(query);
  const status = getRequestStatusByIri(state, ApiRoute.Lead, searchQuery);
  
  return {
    isFetching: !!status && status.pending > 0,
    leads: getResourcesByIri(state, ApiRoute.Lead, searchQuery, undefined, { subresources: LEAD_REPORT_SUBRESOURCE_PATHS }),
    pagination: getPaginationByIri(state, ApiRoute.Lead, searchQuery),
    status: state.statuses,
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      apiUpdate,
    },
    dispatch,
  ),
});

export default compose<LeadReportPageProps, LeadReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate(),
)(LeadReportPage);
