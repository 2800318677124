/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/**
 * @author Kim Khang Koh <kimkhang.koh@sisgroup.sg>
 */

import * as React from 'react';
import moment from 'moment';
import { ApiRoute } from '@ucentric/constants';
import { Card } from 'antd';
import { ContractOptionSearchFieldset, ContractReportTable, PageList } from '@ucentric/react-components';
import { Formik, FormikActions } from 'formik';
import { apiFetch, getPaginationByIri, getRequestStatusByIri, getResourcesByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import { filter, get, isEmpty, isObject, zipObject } from 'lodash';

import styles from './styles.module.css';
import { CONTRACTS_SUBRESOURCE_PATHS } from './constants';
import { ContractReportPageOuterProps, ContractReportPageProps } from './properties';
import { RootState } from 'app/redux/store';

function makeContractSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;

  return {
    itemsPerPage: 10,
    ...apiSearchQuery.contractSearchQuery(query),
    'contractNumber[exists]': true,
    ...(!hasSorter ? { 'order[startDate]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',');
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }

      return value;
    }),
  );
}
const ContractReportPage = ({ action, contracts, isFetching, location, pagination, router, t }: ContractReportPageProps) => {
  const [contractReportSearchQuery, setContractReportSearchQuery] = React.useState(location.query);

  const searchQuery = makeContractSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const initialValues = {};

  const handleReset = () => {
    router.push({
      pathname: location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const handleSubmit = async (values: Contract, { setSubmitting }: FormikActions<Contract>) => {
    // filter out 'sorter' after user execute a new query
    const filterContractReportSearchQuery = Object.keys(contractReportSearchQuery)
      .filter((key) => !key.includes('sort'))
      .reduce((obj: { [key: string]: string }, key: string) => {
        obj[key] = contractReportSearchQuery[key];
        return obj;
      }, {});

    router.push({
      ...location,
      query: {
        ...filterContractReportSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if (isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    action.apiFetch(ApiRoute.Contract, makeContractSearchQuery(query), {
      subrequests: CONTRACTS_SUBRESOURCE_PATHS,
    });
  };

  return (
    <PageList title={t('common:contract_plural') + ' ' + t('common:report')}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
                ? normalizeValues(initialValues)
                : normalizeValues(location.query)
            }
            onReset={handleReset}
            onSubmit={handleSubmit}
            render={() => (
              <div>
                <ContractOptionSearchFieldset
                  value={contractReportSearchQuery}
                  onChange={(values) => {
                    setContractReportSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <ContractReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : contracts}
          loading={isFetching}
          params={makeContractSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: ContractReportPageProps) => {
  const searchQuery = makeContractSearchQuery(query);
  const status = getRequestStatusByIri(state, ApiRoute.Contract, searchQuery);

  return {
    contracts: getResourcesByIri(state, ApiRoute.Contract, makeContractSearchQuery(query), undefined, {
      subresources: CONTRACTS_SUBRESOURCE_PATHS,
    }),
    isFetching: !!status && status.pending > 0,
    pagination: getPaginationByIri(state, ApiRoute.Contract, searchQuery),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
    },
    dispatch,
  ),
});

export default compose<ContractReportPageProps, ContractReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withI18n(),
)(ContractReportPage);
