import * as React from 'react';
import moment from 'moment';
import { AccountCategory, ApiRoute } from '@ucentric/constants';
import { Card } from 'antd';
import { Formik, FormikActions } from 'formik';
import { CustomerOptionSearchFieldset, CustomerReportTable, PageList } from '@ucentric/react-components';
import { apiFetch, apiUpdate, getPaginationByIri, getRequestStatusByIri, getResourcesByIri } from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { filter, get, isEmpty, isObject, zipObject } from 'lodash';
import { translate } from 'react-i18next';

import styles from './styles.module.css';
import { CUSTOMER_ACCOUNTS_SUBRESOURCE_PATHS } from 'app/constants';
import { CustomerReportPageOuterProps, CustomerReportPageProps } from './properties';
import { RootState } from 'app/redux/store';

function makeCustomerAccountSearchQuery(query: {}) {
  const hasSorter = !!query && filter(query, (_, key) => key.includes('sort')).length > 0;

  return {
    categories: [AccountCategory.Customer, AccountCategory.ContactPerson, AccountCategory.Noncustomer],
    itemsPerPage: 10,
    ...apiSearchQuery.customerAccountSearchQuery(query),
    ...(!hasSorter ? { 'order[dateCreated]': 'desc' } : {}),
  };
}

function normalizeValues(values: { [key: string]: any }) {
  return zipObject(
    Object.keys(values),
    Object.values(values).map((value) => {
      if (value && typeof value === 'string') {
        if (value.includes(',')) {
          return value.split(',');
        } else if (value.includes('..')) {
          const dateRange = value.split('..');
          return [dateRange[0] ? moment(dateRange[0]) : null, dateRange[1] ? moment(dateRange[1]) : null];
        }
      }

      return value;
    }),
  );
}

const CustomerReportPage = ({
  action,
  customerAccounts,
  isFetching,
  location,
  pagination,
  router,
  status,
  t,
}: CustomerReportPageProps) => {
  const [customerAccountReportSearchQuery, setCustomerAccountReportSearchQuery] = React.useState(location.query);

  const searchQuery = makeCustomerAccountSearchQuery(location.query);

  const isResetData = get(location, 'query.isReset') === true;

  const initialValues = {};

  const handleReset = () => {
    router.push({
      pathname: location.pathname,
      query: {
        isReset: true,
      },
    });
  };

  const handleSubmit = async (values: CustomerAccount, { setSubmitting }: FormikActions<CustomerAccount>) => {
    router.push({
      ...location,
      query: {
        ...customerAccountReportSearchQuery,
        isReset: false,
        page: 1,
      },
    });

    setSubmitting(false);
  };

  const fetchFn = (query: {}) => {
    if (isEmpty(location.query) || !isObject(location.query) || isResetData) {
      return;
    }

    action.apiFetch(ApiRoute.CustomerAccount, makeCustomerAccountSearchQuery(query), {
      subrequests: CUSTOMER_ACCOUNTS_SUBRESOURCE_PATHS,
    });
  };

  return (
    <PageList title={t('common:customerAccount') + ' ' + t('common:report')}>
      <Card className={styles.card}>
        <div className={styles.actionsPanel}>
          <Formik
            initialValues={
              isEmpty(location.query) || !isObject(location.query) || isResetData
                ? normalizeValues(initialValues)
                : normalizeValues(location.query)
            }
            onSubmit={handleSubmit}
            onReset={handleReset}
            render={(formikProps) => (
              <div>
                <CustomerOptionSearchFieldset
                  value={customerAccountReportSearchQuery}
                  onChange={(values) => {
                    setCustomerAccountReportSearchQuery(values);
                  }}
                />
              </div>
            )}
          />
        </div>
      </Card>

      <Card bordered={false}>
        <CustomerReportTable
          dataSource={isEmpty(location.query) || isResetData ? [] : customerAccounts}
          loading={isFetching}
          params={makeCustomerAccountSearchQuery(location.query)}
          pagination={
            pagination
              ? {
                  current: location.query.page ? Number(location.query.page) : undefined,
                  pageSize: searchQuery.itemsPerPage,
                  total: isEmpty(location.query) || isResetData ? 0 : pagination.totalItems,
                }
              : undefined
          }
          fetchFn={fetchFn}
          queryMode="querystring"
        />
      </Card>
    </PageList>
  );
};

const mapStateToProps = (state: RootState, { location: { query } }: CustomerReportPageProps) => {
  const searchQuery = makeCustomerAccountSearchQuery(query);
  const status = getRequestStatusByIri(state, ApiRoute.CustomerAccount, searchQuery);

  return {
    customerAccounts: getResourcesByIri<CustomerAccount>(state, ApiRoute.CustomerAccount, searchQuery, undefined, {
      subresources: CUSTOMER_ACCOUNTS_SUBRESOURCE_PATHS,
    }),
    isFetching: !!status && status.pending > 0,
    pagination: getPaginationByIri(state, ApiRoute.CustomerAccount, searchQuery),
    status: state.statuses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      apiUpdate,
    },
    dispatch,
  ),
});

export default compose<CustomerReportPageProps, CustomerReportPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate(),
)(CustomerReportPage);
